import * as React from 'react';
import { useParams, useNavigate, Link } from "react-router-dom";
import { useQuery } from '@apollo/client';
import { GET_SPECIFIC_ZAHLUNG } from './graphql';
import LoadingAnimation from '../Utils/LoadingAnimation';

import { TextField, Typography, Button, Container, Grid, Paper, Box } from '@mui/material';
import Title from '../Utils/Title';

// Utility functions for formatting
function formatCurrency(number) {
    return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(number);
}

function formatBoolean(bool) {
    return bool ? 'Ja' : 'Nein';
}

// Format cached date to dd.MM.yyyy
function formatCachedDate(date) {
    if (date) {
        const parts = date.split("-");
        return `${parts[2]}.${parts[1]}.${parts[0]}`;
    }
    return ''; // Important for form validation
}

// Fetch specific Zahlung
function useSpecificZahlung(id) {
    const { loading, error, data } = useQuery(GET_SPECIFIC_ZAHLUNG, {
        variables: { zahlungsId: id },
    });

    if (loading) return { loading: true };
    if (error) return { error: error.message };
    
    return data?.specificZahlung || null;
}

export default function ShowZahlung() {
    const { id: zahlungsID } = useParams();
    const navigate = useNavigate();
    const specificZahlung = useSpecificZahlung(zahlungsID);

    if (specificZahlung?.loading) {
        return <LoadingAnimation/>;
    }

    if (specificZahlung?.error) {
        return <p>Error: {specificZahlung.error}</p>;
    }

    if (!specificZahlung) {
        return <p>Zahlung nicht gefunden.</p>;
    }

    const paechterData = specificZahlung.pacht.paechter;

    return (
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3} alignItems="center" className="show">
                <Grid item xs={12}>
                    <Paper sx={{ p: 3, display: 'flex', flexDirection: 'column' }}>
                        <Box noValidate>
                            <Title>
                                Zahlung über EUR {formatCurrency(specificZahlung.betrag)} von {paechterData.vorname} {paechterData.name} anzeigen
                            </Title>

                            <TextField fullWidth disabled variant="filled" label="Betrag (EUR)" value={formatCurrency(specificZahlung.betrag)} />
                            <TextField fullWidth disabled variant="filled" label="Buchungsdatum" value={formatCachedDate(specificZahlung.buchungsdatum)} />

                            <TextField
                                fullWidth
                                disabled
                                variant="filled"
                                label="Pächter"
                                value={`${paechterData.name}, ${paechterData.vorname}`}
                            />

                            <TextField fullWidth disabled variant="filled" label="Zahlungsart" value={specificZahlung.zahlungsart} />
                            <TextField fullWidth disabled variant="filled" label="Kontierungsnummer" value={specificZahlung.pacht.kontierungsnummer} />
                            <TextField fullWidth disabled variant="filled" label="Hinweis" value={specificZahlung.hinweis} />
                            <TextField fullWidth disabled variant="filled" label="Ausgleichszahlung" value={formatBoolean(specificZahlung.ausgleichszahlung)} />
                            <TextField fullWidth disabled variant="filled" label="Pachtzahlung" value={formatBoolean(specificZahlung.pachtzahlung)} />
                            <TextField fullWidth disabled variant="filled" label="Sonstige Rechnung" value={formatBoolean(specificZahlung.sonstigeRechnung)} />

                            <Button color="primary" variant="contained" onClick={() => navigate(`/zahlung/${zahlungsID}/update`)}>
                                bearbeiten
                            </Button>
                            <Button color="primary" variant="contained" className="backbutton" onClick={() => navigate(-1)}>
                                zurück
                            </Button>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}
