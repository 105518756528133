import * as React from 'react';
import { useParams, useNavigate, Link } from "react-router-dom";
import { useQuery } from '@apollo/client';
import { GET_SPECIFIC_PAECHTER } from './graphql';
import { GET_PARZELLEN } from '../Parzellen/graphql';
import LoadingAnimation from '../Utils/LoadingAnimation';

import { TextField, Typography, Container, Grid, Paper, Box, Button } from '@mui/material';
import Title from '../Utils/Title';

function useSpecificPaechter(id) {
    const { loading, error, data } = useQuery(GET_SPECIFIC_PAECHTER, {
        variables: { paechterId: id },
    });
    return { loading, error, paechter: data?.specificPaechter || {} };
}

function useParzellen() {
    const { loading, error, data } = useQuery(GET_PARZELLEN);
    return { loading, error, parzellen: data?.parzellen || [] };
}

function formatCachedDate(date) {
    return date ? date.split("-").reverse().join(".") : '';
}

function ShowPaechter() {

    const { id } = useParams();
    const { loading, error, paechter } = useSpecificPaechter(id);
    const { parzellen } = useParzellen();
    const navigate = useNavigate();


    if (loading) return <LoadingAnimation/>;
    if (error) return <p>Error: {error.message}</p>;

    const goBack = () => navigate(-1);
    const handleEditButton = () => navigate(`/paechter/${id}/update`);

    return (
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3} alignItems="center" className='show'>
                <Grid item xs={12}>
                    <Paper sx={{ p: 3, display: 'flex', flexDirection: 'column' }}>
                        <Box noValidate>
                            <Title>Pächter "{paechter.vorname} {paechter.name}" anzeigen</Title>
                            {[
                                { label: "Anrede", value: paechter.anrede },
                                { label: "Nachname", value: paechter.name },
                                { label: "Vorname", value: paechter.vorname },
                                { label: "Strasse und Hausnummer", value: paechter.strasse },
                                { label: "PLZ", value: paechter.plz },
                                { label: "Ort", value: paechter.ort },
                                { label: "Vorherige Meldeadresse", value: paechter.vorherigeMeldeadresse },
                                { label: "Telefon Festnetz", value: paechter.telefonFestnetz },
                                { label: "Telefon Mobil", value: paechter.telefonMobil },
                                { label: "Email-Adresse", value: paechter.email },
                                { label: "Geburtsdatum", value: formatCachedDate(paechter.geburtsdatum) },
                                { label: "Name Partner", value: paechter.namePartner },
                                { label: "Geburtsname", value: paechter.geburtsname },
                                { label: "Geburtsdatum Partner", value: formatCachedDate(paechter.geburtsdatumPartner) },
                                { label: "IBAN", value: paechter.iban },
                                { label: "Ausweisnummer Pächter", value: paechter.ausweisnummerPaechter },
                                { label: "Ausweisnummer Partner", value: paechter.ausweisnummerPartner },
                                { label: "Zusatzpflege", value: paechter.zusatzpflege, multiline: true, rows: 3 },
                                { label: "Auflagen", value: paechter.auflagen, multiline: true, rows: 10 },
                                { label: "Auflagenfrist", value: formatCachedDate(paechter.auflagenfrist) },
                                { label: "Stromzählerart", value: paechter.stromzaehlerart },
                                { label: "Antrag PV", value: formatCachedDate(paechter.antragPV) },
                                { label: "Prüfung PV", value: formatCachedDate(paechter.pruefungPV) },
                                { label: "Duldungen", value: paechter.duldungen, multiline: true, rows: 5 },
                                { label: "Briefkasten Nr.", value: paechter.briefkastenNr },
                                { label: "KFZ-Kennzeichen", value: paechter.kfzKennzeichen },
                                { label: "Erschließungskosten (EUR)", value: paechter.erschliessungskosten },
                                { label: "Ausgeschieden am", value: formatCachedDate(paechter.ausgeschiedenAm) },
                            ].map((field, index) => (
                                <TextField
                                    key={index}
                                    fullWidth
                                    disabled
                                    variant='filled'
                                    label={field.label}
                                    value={field.value || ''}
                                    multiline={field.multiline || false}
                                    rows={field.rows || 1}
                                />
                            ))}
                            <Button color="primary" variant="contained" onClick={handleEditButton}>bearbeiten</Button>
                            <Button color="primary" variant="contained" className='backbutton' onClick={goBack}>zurück</Button>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}

export default ShowPaechter;