
import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import { useQuery } from '@apollo/client';
import { GET_GESAMTRECHNUNGEN } from './graphql';
import { useParams } from "react-router-dom";
import LoadingAnimation from '../Utils/LoadingAnimation';

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        padding: 10,
    },
    section: {
        flexDirection: 'column',
        fontSize: '11px',
        minHeight: 30,
    },
    header: {
        borderBottom: '1px solid black',
        paddingBottom: 7,
        fontSize: 12,
    },
    row: {
        flexDirection: 'row',
        padding: 5,
    },
    evenRow: {
        backgroundColor: '#f0f0f0',
    },
    oddRow: {
        backgroundColor: '#ffffff',
    },
    cell: {
        padding: 2,
    },
    currencyCell: {
        padding: 4,
        textAlign: 'right',
    },
    spacer: {
        height: 50,
    },
    name: {
        width: '40%',
    },
    parzelle: {
        width: '30%',
    },
    zahlbetrag: {
        width: '30%',
        textAlign: 'right',
    },
    
});

const ReportingGesamtrechnung = () => {
    const { loading: loadingGesamtrechnungen, error: errorGesamtrechnungen, data: dataGesamtrechnungen } = useQuery(GET_GESAMTRECHNUNGEN);

    if (loadingGesamtrechnungen) return <LoadingAnimation />;
    if (errorGesamtrechnungen) return <Text>Error loading Gesamtrechnungen data</Text>;

    const params = useParams();
    const abrechnungsperiode = parseInt(params.id);

    //console.log('Abrechnungsperiode: ', abrechnungsperiode);
    

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(amount);
    };

    const stripTime = (date) => new Date(date.getFullYear(), date.getMonth(), date.getDate());


    const gesamtrechnungenList = dataGesamtrechnungen?.gesamtrechnungen
    .filter((item) => item.abrechnungsperiode === abrechnungsperiode)
    .filter((item) => {
        const pachtendeDate = item.pacht?.pachtende ? stripTime(new Date(item.pacht.pachtende)) : null;
        const cutoffDate = stripTime(new Date(abrechnungsperiode, 11, 31));
    
        return pachtendeDate === null || pachtendeDate > cutoffDate;
    })
    .map((item) => ({
        name: item.pacht?.paechter?.name || '',
        vorname: item.pacht?.paechter?.vorname || '',
        paechter: `${item.pacht?.paechter?.name}, ${item.pacht?.paechter?.vorname}`,
        parzelle: item.parzelle?.standort || '',
        zahlbetrag: formatCurrency(
            Number(item.differenzsaldo) === 0 ? item.endsaldo : item.differenzsaldo
        ),
    }))
    .sort((a, b) => {
        const nameComparison = a.name.localeCompare(b.name);
        return nameComparison !== 0 ? nameComparison : a.vorname.localeCompare(b.vorname);
    }) || [];


    

    // Calculate totals
    const summeSoll = gesamtrechnungenList
    .filter((item) => parseFloat(item.zahlbetrag.replace(/[^\d,-]/g, '').replace(',', '.')) < 0)
    .reduce((acc, item) => acc + parseFloat(item.zahlbetrag.replace(/[^\d,-]/g, '').replace(',', '.')), 0);

    const summeHaben = gesamtrechnungenList
    .filter((item) => parseFloat(item.zahlbetrag.replace(/[^\d,-]/g, '').replace(',', '.')) > 0)
    .reduce((acc, item) => acc + parseFloat(item.zahlbetrag.replace(/[^\d,-]/g, '').replace(',', '.')), 0);

    const saldo = summeSoll + summeHaben;




    return (
        <PDFViewer>
            <Document>
                <Page size="A4" orientation="landscape" style={styles.page}>
                    <View style={styles.section}>
                        {/* Table Header */}
                        <View style={styles.row} fixed>
                            <Text style={[styles.cell, styles.header, styles.name]}>Name, Vorname</Text>
                            <Text style={[styles.cell, styles.header, styles.parzelle]}>Parzelle</Text>
                            <Text style={[styles.cell, styles.header, styles.zahlbetrag]}>Zahlbetrag</Text>
                        </View>

                        {/* Table Rows */}
                        {gesamtrechnungenList.map((item, index) => (
                            <View key={index} style={[styles.row, index % 2 === 0 ? styles.evenRow : styles.oddRow]}>
                                <Text style={[styles.cell, styles.name]}>{item?.paechter}</Text>
                                <Text style={[styles.cell, styles.parzelle]}>{item?.parzelle}</Text>
                                <Text style={[styles.currencyCell, styles.zahlbetrag]}>{item?.zahlbetrag}</Text>
                            </View>
                        ))}

                        <View style={styles.spacer} />
                        
                        {/* Totals */}
                        
                        <View style={[styles.row, styles.oddRow]}>
                            <Text style={[styles.cell, styles.name]}>Anzahl berücksichtigter Gesamtrechnungen</Text>
                            <Text style={[styles.cell, styles.parzelle]}></Text>
                            <Text style={[styles.currencyCell, styles.zahlbetrag]}>{gesamtrechnungenList.length}</Text>
                        </View>

                        <View style={[styles.row, styles.evenRow]}>
                            <Text style={[styles.cell, styles.name]}>Summe Soll</Text>
                            <Text style={[styles.cell, styles.parzelle]}></Text>
                            <Text style={[styles.currencyCell, styles.zahlbetrag]}>{formatCurrency(summeSoll)}</Text>
                        </View>
                        <View style={[styles.row, styles.oddRow]}>
                            <Text style={[styles.cell, styles.name]}>Summe Haben</Text>
                            <Text style={[styles.cell, styles.parzelle]}></Text>
                            <Text style={[styles.currencyCell, styles.zahlbetrag]}>{formatCurrency(summeHaben)}</Text>
                        </View>
                        <View style={[styles.row, styles.evenRow]}>
                            <Text style={[styles.cell, styles.name]}>Saldo</Text>
                            <Text style={[styles.cell, styles.parzelle]}></Text>
                            <Text style={[styles.currencyCell, styles.zahlbetrag]}>{formatCurrency(saldo)}</Text>
                        </View>
                    </View>
                </Page>
            </Document>
        </PDFViewer>
    );


};



export default ReportingGesamtrechnung;

