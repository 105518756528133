//import { useQuery, gql, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';


export const GET_ZAHLUNGEN = gql`
    query GetZahlungen {
        zahlungen{
            id
            betrag
            buchungsdatum
            pacht {
                id
                paechter {
                    id
                    name
                    vorname
                }
                parzelle{
                    id
                    standort
                }
                pachtbeginn
                pachtende
                kontierungsnummer
            }
            zahlungsart
            hinweis
            ausgleichszahlung
            pachtzahlung
            sonstigeRechnung
        }
    }
`;


export const GET_SPECIFIC_ZAHLUNG = gql`
    query GetSpecificZahlung($zahlungsId: ID!) {
        specificZahlung(zahlungsId: $zahlungsId) {
            id
            betrag
            buchungsdatum
            pacht {
                id
                paechter {
                    id
                    name
                    vorname
                }
                parzelle{
                    id
                    standort
                }
                pachtbeginn
                pachtende
                kontierungsnummer
            }
            zahlungsart
            hinweis
            ausgleichszahlung
            pachtzahlung
            sonstigeRechnung
        }
    }
`;


export const GET_ARCHIVIERTE_ZAHLUNGEN = gql`
    query GetArchivierteZahlungen {
        archivierteZahlungen{
            id
            betrag
            buchungsdatum
            pacht {
                id
                paechter {
                    id
                    name
                    vorname
                }
                parzelle{
                    id
                    standort
                }
                pachtbeginn
                pachtende
                kontierungsnummer
            }
            zahlungsart
            hinweis
            ausgleichszahlung
            pachtzahlung
            sonstigeRechnung
        }
    }
`;


export const GET_ZAHLUNGEN_OF_PACHT_THIS_YEAR = gql`
    query GetZahlungenOfPacht($pachtID: ID!) {
        zahlungenOfPachtThisYear(pachtId: $pachtID){
            id
            betrag
            buchungsdatum
            pacht {
                id
                paechter {
                    id
                    name
                    vorname
                }
                parzelle{
                    id
                    standort
                }
                pachtbeginn
                pachtende
                kontierungsnummer
            }
            zahlungsart
            hinweis
            ausgleichszahlung
            pachtzahlung
            sonstigeRechnung
        }
    }
`;


export const GET_ZAHLUNGEN_OF_PACHT_LAST_YEAR = gql`
    query GetZahlungenOfPacht($pachtID: ID!) {
        zahlungenOfPachtLastYear(pachtId: $pachtID){
            id
            betrag
            buchungsdatum
            pacht {
                id
                paechter {
                    id
                    name
                    vorname
                }
                parzelle{
                    id
                    standort
                }
                pachtbeginn
                pachtende
                kontierungsnummer
            }
            zahlungsart
            hinweis
            ausgleichszahlung
            pachtzahlung
            sonstigeRechnung
        }
    }
`;


export const CREATE_ZAHLUNG = gql`
    mutation CreateZahlung($betrag: Decimal!, $buchungsdatum: Date, $pacht: ID!, $zahlungsart: String!, $hinweis: String!, $ausgleichszahlung: Boolean!, $pachtzahlung: Boolean!, $sonstigeRechnung: Boolean!) {
        createZahlung(
            betrag: $betrag, buchungsdatum: $buchungsdatum, pacht: $pacht, zahlungsart: $zahlungsart, hinweis: $hinweis, ausgleichszahlung: $ausgleichszahlung, pachtzahlung: $pachtzahlung, sonstigeRechnung: $sonstigeRechnung) {
            id
            betrag
            buchungsdatum
            pacht
            zahlungsart
            hinweis
            ausgleichszahlung
            pachtzahlung
            sonstigeRechnung
        }
    }
`;

export const UPDATE_ZAHLUNG = gql`
    mutation UpdateZahlung($id: ID!, $betrag: Decimal!, $buchungsdatum: Date!, $pacht: ID!, $zahlungsart: String!, $hinweis: String, $ausgleichszahlung: Boolean!, $pachtzahlung: Boolean!, $sonstigeRechnung: Boolean!) {
        updateZahlung(id: $id, betrag: $betrag, buchungsdatum: $buchungsdatum, pacht: $pacht, zahlungsart: $zahlungsart, hinweis: $hinweis, ausgleichszahlung: $ausgleichszahlung, pachtzahlung: $pachtzahlung, sonstigeRechnung: $sonstigeRechnung) {
            id
            betrag
            buchungsdatum
            pacht
            zahlungsart
            hinweis
            ausgleichszahlung
            pachtzahlung
            sonstigeRechnung
        }
    }
`;


export const DELETE_ZAHLUNG = gql`
    mutation DeleteZahlung($id: ID!){
        deleteZahlung(id: $id) {
            ok
        }
    }
`;
